import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { newConfirmResetPassword } from '../../../store/sagas/auth';
import stylesUaubox from './../login.module.scss';
import stylesScarlet from './../loginScarlet.module.scss';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import React from 'react';

const ConfirmResetPassword = () => {
  const dispatch = useDispatch();
  const tenantSelected = useSelector((state) => state?.form?.signInForm?.values?.tenant);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const params = useParams();
  const tenant = params?.tenant;

  const styles = tenant === 'scarlet' ? stylesScarlet : stylesUaubox;

  const { handleSubmit, getValues, setValue, setError, clearErrors, formState } = useForm();

  const onValidSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      return setError('confirmPassword', { message: 'Senhas não correspondem' });
    }

    setLoading(true);

    const result = await newConfirmResetPassword(token, data.password, tenant);

    if (!result) {
      setLoading(false);
      return;
    }

    // eslint-disable-next-line
    return history.push(`/login/password${location?.search}`);
  };

  const handleChange = (field, event) => {
    clearErrors(field);
    setValue(field, event.target.value);
  };

  useEffect(() => {
    if (!params?.token) {
      toast.error('Token inválido!');
      // eslint-disable-next-line
      history.push(`/login/${location?.search}`);
    }
    setToken(params?.token);
    if (params?.tenant) {
      dispatch(change('signInForm', 'tenant', params.tenant));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.login}>
      <div className={styles.grid}>
        <div className={styles.mainBox}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/lp-scarletbox/logo_scarlet_preto.png'
            }
            alt={tenantSelected === 'uaubox' ? 'UAUBox' : 'Scarlet'}
          />
          <h1>Recuperar Senha</h1>
          <form id="password" onSubmit={handleSubmit(onValidSubmit)}>
            <div className={styles.actionBox}>
              <div className={styles.inputBox}>
                <h3>Nova Senha</h3>
                <Input
                  type="password"
                  value={getValues('password')}
                  placeholder="Digite sua senha"
                  onChange={(e) => handleChange('password', e)}
                  readOnly={loading}
                />
              </div>
              <div className={styles.inputBox}>
                <h3>Confirmar Nova Senha</h3>
                <Input
                  type="password"
                  value={getValues('confirmPassword')}
                  placeholder="Repita sua senha"
                  onChange={(e) => handleChange('confirmPassword', e)}
                  valid={formState.errors.confirmPassword ? false : null}
                  readOnly={loading}
                />
              </div>
              <Button
                label="Redefinir senha"
                type="submit"
                form="password"
                disabled={loading || !getValues('password') || !getValues('confirmPassword')}
              />
            </div>
          </form>
        </div>
        <div className={styles.imgLogin}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg'
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmResetPassword;
