import { useEffect, useRef, useState } from 'react';

import styles from './input.module.scss';
import React from 'react';

const Input = ({
  className,
  containerClassName,
  disabled,
  readOnly,
  onChange,
  icon,
  valid = null,
  value = '',
  maxLength = 100,
  placeholder = '',
  type = 'text',
  loading = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    if (maxLength && e.target.value.length > maxLength) return;
    setInputValue(e.target.value);
    onChange && onChange(e);
  };

  const handleClick = () => {
    return inputRef.current?.focus();
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={
        styles.container +
        (containerClassName ? ` ${containerClassName}` : '') +
        (disabled ? ` ${styles.disabled}` : '') +
        (readOnly ? ` ${styles.readOnly}` : '') +
        (valid === false ? ` ${styles.invalid}` : '') +
        (valid === true ? ` ${styles.valid}` : '')
      }
      onClick={handleClick}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <input
        type={type}
        value={inputValue}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={handleChange}
        className={styles.input + (className ? ` ${className}` : '')}
        ref={inputRef}
        {...props}
      />
    </div>
  );
};

export default Input;
