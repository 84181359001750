import React from 'react';
import Helmet from 'react-helmet';

const descriptionPage =
  'Esse é o Espaço UAUBox, aqui você pode encontrar tudo sobre a sua Box e também do Universo UAUBox';
const keywordsEnv = process.env.REACT_APP_METATAG;

const SEO = ({ title, description = descriptionPage, keywords }) => {
  const defaultTitle = 'Espaço UAUBox';
  const keywordsInsert = keywords ? `, ${keywords}` : '';
  return (
    <Helmet>
      <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={`${keywordsEnv}${keywordsInsert}`} />
    </Helmet>
  );
};

export default SEO;
