import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Input from '../../components/Input';
import stylesUaubox from './login.module.scss';
import stylesScarlet from './loginScarlet.module.scss';
import validate from '../../store/utils/validation';
import { toast } from 'react-toastify';
import { checkCpf, checkEmail, haveTelephone, sendCode, verifyCode } from '../../store/sagas/auth';
import emailOrCpf from '../../store/utils/emailOrCpf';
import { formatter } from '../../store/utils/formatter';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

const legacyUrl = `${process.env.REACT_APP_REGISTRATION_WEBSITE_DEFAULT}`;

const LoginByCode = () => {
  const history = useHistory();
  let tenantSelected = useSelector((state) => state?.form?.signInForm?.values?.tenant);
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [codeSent, setCodeSent] = useState();
  const [sentTo, setSentTo] = useState('');
  const [counter, setCounter] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [codeMethods, setCodeMethods] = useState([
    { method: 'sms', display: 'SMS' },
    { method: 'email', display: 'E-mail' },
  ]);

  if (!tenantSelected) {
    tenantSelected = 'uaubox';
  }

  const styles = tenantSelected === 'scarlet' ? stylesScarlet : stylesUaubox;

  const { getValues, handleSubmit, setValue, setError, clearErrors, formState } = useForm();

  const onValidSubmit = async (data) => {
    setLoading(true);
    setInvalidUser(false);

    if (!codeSent) onSubmitUser(data.emailOrCpf);
    else onSubmitCode(data);
  };

  const onSubmitUser = async (inputValue) => {
    let existingUser = false;
    let success = false;
    const inputValueType = emailOrCpf(inputValue);
    try {
      if (inputValueType === 'email') {
        if (!validate.email(inputValue)) {
          toast.error('Email inválido!');
          setLoading(false);
          return setError('emailOrCpf', { message: 'Email inválido' });
        }
        const emailCheck = await checkEmail(inputValue, tenantSelected);
        existingUser = emailCheck.emailInUse;
        success = emailCheck.success;
      } else if (inputValueType === 'cpf') {
        if (!validate.cpf(inputValue)) {
          toast.error('CPF inválido!');
          setLoading(false);
          return setError('emailOrCpf', { message: 'CPF inválido' });
        }
        const cpfCheck = await checkCpf(inputValue, tenantSelected);
        existingUser = cpfCheck.cpfInUse;
        success = cpfCheck.success;
      }

      if (!success) {
        setLoading(false);
        return;
      }

      if (!existingUser) {
        setLoading(false);
        setInvalidUser(true);
        return setError('emailOrCpf', { message: 'Usuário inválido' });
      }

      validateLoginMethods();
    } catch (error) {
      console.log(`Error at onValidSubmit on index. Error: ${error}`);
    }
    setLoading(false);
  };

  const onSubmitCode = async (data) => {
    const request = {
      emailOrCpf: data.emailOrCpf,
      code: data.code,
      type: codeSent || '',
    };
    const result = await verifyCode(request, tenantSelected);

    if (!result) {
      setLoading(false);
      return;
    }

    // eslint-disable-next-line
    return history.push(`${result}${location?.search}`);
  };

  const handleChange = (field, event) => {
    if (field === 'emailOrCpf') {
      clearErrors(field);
      setValidUser(false);
      setCodeSent(undefined);

      let value = event.target.value.trim();
      value = event.target.value.toLowerCase();
      if (emailOrCpf(value) === 'cpf') {
        value = formatter.cpf(value);
      }
      setValue(field, value);
    }
    if (field === 'code') {
      setValue(field, event.target.value);
    }
  };

  const validateLoginMethods = async () => {
    const hasPhoneNumber = haveTelephone(getValues('emailOrCpf'), tenantSelected);
    if (!hasPhoneNumber) setCodeMethods((prev) => prev.filter((item) => item.method === 'email'));

    setValidUser(true);
  };

  const sendLoginCode = async (method) => {
    const request = {
      emailOrCpf: getValues('emailOrCpf'),
      type: method,
    };
    const response = await sendCode(request, tenantSelected);

    if (!response) {
      return toast.error(
        'Ocorreu um erro ao enviar o código de verificação. Tente novamente mais tarde.'
      );
    }

    setBlocked(true);
    setCounter(60);

    setCodeSent(method);
    setSentTo(response);
  };

  useEffect(() => {
    if (!blocked) return;

    if (counter <= 0) {
      setBlocked(false);
      return;
    }

    setTimeout(() => setCounter(counter - 1), 1000);
    return;
    // eslint-disable-next-line
  }, [counter]);

  return (
    <div className={styles.login}>
      <div className={styles.grid}>
        <div className={styles.mainBox}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/lp-scarletbox/logo_scarlet_preto.png'
            }
            alt={tenantSelected === 'uaubox' ? 'UAUBox' : 'Scarlet'}
          />
          <h1>Chave de Acesso</h1>
          <form id="user" onSubmit={handleSubmit(onValidSubmit)}>
            <div className={styles.actionBox}>
              <div className={styles.inputBox}>
                <h3>Informe e-mail ou CPF</h3>
                <Input
                  type="text"
                  value={getValues('emailOrCpf')}
                  placeholder="Digite seu e-mail ou CPF"
                  onChange={(e) => handleChange('emailOrCpf', e)}
                  valid={formState.errors.emailOrCpf ? false : validUser ? true : null}
                  readOnly={loading}
                />
              </div>
              {invalidUser && (
                <div className={styles.messageBox}>
                  <div className={styles.errorBox}>
                    Ops. Parece que seu usuário não está cadastrado no sistema. Tente outro e-mail
                    ou cpf ou caso ainda não seja assinante, faça sua assinatura.
                  </div>
                  {/* eslint-disable-next-line */}
                  <a href={`${legacyUrl}${location?.search}`}>Fazer minha assinatura</a>
                </div>
              )}
              {validUser && !codeSent && (
                <div className={styles.messageBox}>
                  <div className={styles.successBox}>
                    Usuário já cadastrado. Escolha por onde deseja receber o código de verificação
                    para prosseguir com a compra!
                  </div>
                  <div className={styles.codeMethods}>
                    {codeMethods.map((method) => (
                      // eslint-disable-next-line
                      <a key={method.method} onClick={() => sendLoginCode(method.method)}>
                        {method.display}
                      </a>
                    ))}
                  </div>
                </div>
              )}
              {validUser && codeSent && (
                <div className={styles.messageBox}>
                  <div className={styles.successBox}>
                    Verifique o código enviado para o{codeSent === 'sms' ? ' número ' : ' e-mail '}
                    {sentTo}
                  </div>
                </div>
              )}
              {codeSent && (
                <>
                  <div className={styles.inputBox}>
                    <h3>Código de Verificação</h3>
                    <Input
                      className={styles.codeInput}
                      type="number"
                      value={getValues('code')}
                      placeholder="------"
                      onChange={(e) => handleChange('code', e)}
                      maxLength={6}
                      valid={formState.errors.code ? false : null}
                      readOnly={loading}
                    />
                  </div>
                  {/* eslint-disable-next-line */}
                  <a
                    className={blocked ? styles.blocked : styles.resend}
                    onClick={() => (blocked ? '' : sendLoginCode(codeSent))}
                  >
                    {blocked
                      ? `Aguarde 00:${counter
                          .toString()
                          .padStart(2, '0')} para enviar um código novamente.`
                      : 'Enviar novamente'}
                  </a>
                </>
              )}
              <Button
                label="Continuar"
                type="submit"
                form="user"
                disabled={
                  loading ||
                  !getValues('emailOrCpf') ||
                  (validUser && !codeSent) ||
                  (codeSent && (!getValues('code') || getValues('code').length < 6))
                }
              />
            </div>
          </form>
        </div>
        {/* eslint-disable-next-line */}
        <div className={styles.imgLogin}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg'
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default LoginByCode;
