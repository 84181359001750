export const cpf = (value) => {
  if (value.length >= 11) {
    return value.replace(/^(\d{3})\D*(\d{3})\D*(\d{3})\D*(\d{1,2})$/g, '$1.$2.$3-$4');
  } else if (value.length >= 7) {
    return value.replace(/^(\d{3})\D*(\d{3})\D*(\d{1,3})$/g, '$1.$2.$3');
  } else if (value.length >= 4) {
    return value.replace(/^(\d{3})\D*(\d{1,3})$/g, '$1.$2');
  }

  return value;
};

export const formatter = {
  cpf,
};
