import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { cinzaMaisClaro } from '../../themes/colors';

import MenuItems from './MenuItems/MenuItems';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '16.5rem',
    minHeight: '100vh',
    background: cinzaMaisClaro,
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
  },
  logoUAU: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    '& img': {
      height: '50px',
    },
    overflow: 'hidden',
  },
}));

const Menu = () => {
  const classes = useStyles();
  const { tenantName } = useSelector((state) => state?.auth);
  return (
    <div className={classes.root}>
      <div className={classes.logoUAU}>
        {tenantName === 'scarlet' ? (
          <img
            src="https://d2ivdm85owtv7v.cloudfront.net/images/lp-scarletbox/logo_scarlet_preto.png"
            alt="Scarlet"
          />
        ) : (
          <img
            src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/uau_logo_coral_2.png"
            alt="UAUBox"
          />
        )}
      </div>
      <MenuItems />
    </div>
  );
};

export default Menu;
