import styles from './button.module.scss';
import React from 'react';

function Button({
  label,
  onClick,
  className,
  disabled = false,
  height = 48,
  variant = 'default',
  ...props
}) {
  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    onClick && onClick(event);
  };

  return (
    <button
      className={
        styles[variant] +
        (className ? ` ${className}` : '') +
        (disabled ? ` ${styles.disabled}` : '')
      }
      onClick={handleClick}
      style={{ height: `${height}px` }}
      {...props}
    >
      <span className={styles.label}>
        <p>{label}</p>
      </span>
    </button>
  );
}

export default Button;
