import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { signIn } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import validate from '../../../store/utils/validation';
import { toast } from 'react-toastify';
import stylesUaubox from './../login.module.scss';
import stylesScarlet from './../loginScarlet.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';

const LoginByPassword = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  let tenantSelected = useSelector((state) => state?.form?.signInForm?.values?.tenant);

  if (!tenantSelected) {
    tenantSelected = 'uaubox';
  }

  const styles = tenantSelected === 'scarlet' ? stylesScarlet : stylesUaubox;

  const { handleSubmit, getValues, setValue, setError, clearErrors, formState } = useForm();

  const onValidSubmit = async (data) => {
    setInvalidCredentials(false);

    if (!validate.email(data.email)) {
      toast.error('Email inválido!');
      return setError('email', { message: 'Email inválido' });
    }

    setLoading(true);

    dispatch(
      signIn(tenantSelected, {
        email: data?.email,
        password: data?.password,
        grantType: 'password',
      })
    );

    // const result = false;

    // if (!result) {
    //   setLoading(false);
    //   setInvalidCredentials(true);
    //   return;
    // }

    setLoading(false);
  };

  const handleChange = (field, event) => {
    clearErrors(field);
    setValue(field, event.target.value);
  };

  return (
    <div className={styles.login}>
      <div className={styles.grid}>
        <div className={styles.mainBox}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/lp-scarletbox/logo_scarlet_preto.png'
            }
            alt={tenantSelected === 'uaubox' ? 'UAUBox' : 'Scarlet'}
          />
          <h1>Efetue Seu Login</h1>
          <form id="login" onSubmit={handleSubmit(onValidSubmit)}>
            <div className={styles.actionBox}>
              <div className={styles.inputBox}>
                <h3>Informe seu e-mail</h3>
                <Input
                  type="text"
                  value={getValues('email')}
                  placeholder="Digite seu e-mail"
                  onChange={(e) => handleChange('email', e)}
                  valid={formState.errors.email ? false : null}
                  readOnly={loading}
                />
              </div>
              <div className={styles.inputBox}>
                <h3>Informe sua senha</h3>
                <Input
                  type="password"
                  value={getValues('password')}
                  placeholder="Digite sua senha"
                  onChange={(e) => handleChange('password', e)}
                  valid={formState.errors.password ? false : null}
                  readOnly={loading}
                />
              </div>
              <div className={styles.messageBox}>
                {invalidCredentials && (
                  <div className={styles.errorBox}>
                    Ops. Parece que seu e-mail ou sua senha estão incorretos. Tente novamente.
                  </div>
                )}
                <Link
                  className={!invalidCredentials ? styles.forgotPassword : undefined}
                  to={'/login/password/recovery'}
                >
                  Esqueceu sua senha?
                </Link>
              </div>
              <Button
                label="Continuar"
                type="submit"
                form="login"
                disabled={loading || !getValues('email') || !getValues('password')}
              />
            </div>
          </form>
        </div>
        <div className={styles.imgLogin}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg'
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default LoginByPassword;
