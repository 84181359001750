import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { setToken, logout, getTenantToken } from '../services/auth';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';

import { logException, logEvent } from '../../components/utils/GoogleAnalytics';
import dataLayerEvents from '../../components/utils/dataLayerEvents';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;
const REGISTRATION_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/registration`;

export async function checkEmail(email, tenant) {
  let emailInUse = false;
  let success = false;

  try {
    const response = await axios.get(
      `${REGISTRATION_API}/${tenant}/user/check-email/new?email=${email}`
    );
    emailInUse = response?.data?.emailInUse;
    success = true;
  } catch (error) {
    toast.error('Erro na verificação do email');
    console.log(`Error on checkEmail. Email: ${email} Tenant: ${tenant} Error: ${error}`);
  }

  return { emailInUse, success };
}

export async function checkCpf(cpf, tenant) {
  let cpfInUse = false;
  let success = false;

  try {
    const response = await axios.get(
      `${REGISTRATION_API}/${tenant}/customers/check-cpf/new?cpf=${cpf}`
    );
    cpfInUse = response?.data?.cpfInUse;
    success = true;
  } catch (error) {
    toast.error('Erro na verificação do CPF');
    console.log(`Error on checkCpf. CPF: ${cpf} Tenant: ${tenant} Error: ${error}`);
  }

  return { cpfInUse, success };
}

export async function haveTelephone(emailOrCpf, tenant) {
  try {
    const response = await axios.post(`${BASE_API}/customer/haveTelephone`, { emailOrCpf, tenant });
    return response?.data;
  } catch (error) {
    console.log(`Error on haveTelephone. User ID: ${emailOrCpf} Tenant:${tenant} Error:${error} `);
    return false;
  }
}

export async function sendCode(sendCode, tenant) {
  try {
    const response = await axios.post(`${BASE_API}/twilio/sendCode`, { ...sendCode, tenant });
    return response?.data?.sentTo;
  } catch (error) {
    console.log(
      `Error on sendCode. EmailOrCpf: ${sendCode.emailOrCpf} Tenant: ${tenant} Type: ${sendCode.type} Error:${error}`
    );
    return null;
  }
}

export async function verifyCode(verifyCode, tenant) {
  try {
    const response = await axios.post(`${BASE_API}/twilio/verifyCode`, { ...verifyCode, tenant });
    const { accessToken } = response.data;
    setToken(accessToken);
    logEvent('SignIn Success', 'dashboard.login.signinsuccession', 'User login sucessfully');
    dataLayerEvents.login();
    let pathRedirect = sessionStorage.getItem('pathRedirect');
    if (!pathRedirect) pathRedirect = '/dashboard';
    return pathRedirect;
  } catch (error) {
    if (error?.response?.status === 401) {
      toast.error('Código incorreto.');
      return null;
    }
    console.log(
      `Error on verifyCode. EmailOrCpf:${verifyCode.emailOrCpf} Tenant:${tenant} Type:${verifyCode.type} Code: ${verifyCode.code} Error:${error}`
    );
    toast.error('Houve um erro inesperado.');
    return null;
  }
}

export function* signIn({ tenant, payload: { grantType, email, password, refreshToken } }) {
  try {
    const payload = password
      ? { tenant, grantType, email, password }
      : { tenant, grantType, email, refreshToken };
    const response = yield axios.post(`${BASE_API}/${tenant}/account/sign-in`, payload);

    if (response.status === 200) {
      yield put(fromActions.signInSuccess(response.data, tenant));
      const { accessToken } = response.data;
      setToken(accessToken);
      logEvent('SignIn Success', 'dashboard.login.signinsuccession', 'User login sucessfully');
      dataLayerEvents.login();
      let pathRedirect = sessionStorage.getItem('pathRedirect');
      if (!pathRedirect) pathRedirect = '/dashboard';
      yield put(push(pathRedirect));
      sessionStorage.removeItem('pathRedirect');

      yield put(reset('signInForm'));
    }
  } catch (error) {
    console.error(error.message);
    logException(`Username or password incorrect ${email} - ${password}: ${error.message}`, false);
    toast.error('Usuário e/ou senha incorretos.');
    yield put(fromActions.signInError(error));
    if (grantType === 'refresh_token') {
      yield put(push('/'));
    }
  }
}

export function* checkToken({ token }) {
  try {
    const info = atob(token);
    const index = info.lastIndexOf(':');
    if (index < 0) {
      throw index;
    }
    const email = info.substring(0, index);
    const ticket = info.substring(index + 1);
    if (!email || email.trim() === '' || !ticket || ticket.trim() === '') {
      throw index;
    }
    yield put(fromActions.checkTokenSuccess());
  } catch (error) {
    toast.error('Link inválido, repita o procedimento.');
    yield put(push('/login'));
    yield put(fromActions.checkTokenError(error));
    logException(
      `User have troubles with Recover Password. Token - ${token}. Error - ${error.message}`,
      false
    );
    console.error(error);
  }
}

export function* signOut() {
  try {
    const tenant = yield select(getTenantToken);
    sessionStorage.setItem('tenantLogout', tenant);
    logout();
    yield put(fromActions.signOutSuccess());
    yield put(push('/login'));
    logEvent('SignOut Success', 'dashboard.login.signoutsuccession', 'User sign out sucessfully');
  } catch (error) {
    logException(`signOut: ${error.message}`, false);
    console.error(error);
  }
}

export async function newResetPassword(tenant, email) {
  try {
    await axios.post(`${BASE_API}/${tenant}/account/reset-password`, {
      tenant,
      email,
    });
    return true;
  } catch (error) {
    console.log(`Error on resetPassword: `, error);
    return false;
  }
}

export function* resetPassword({ tenant, email }) {
  try {
    const payload = {
      tenant,
      email,
    };

    yield axios.post(`${BASE_API}/${tenant}/account/reset-password`, payload);

    toast.success(
      'Mig, se o seu email estiver em nossa base, você receberá um link para alteração no seu email, que tal conferir?'
    );
    yield put(push('/login'));
    logEvent(
      'Reset password Success',
      'dashboard.login.resetpassword',
      'User reset password sucessfully'
    );
  } catch (error) {
    toast.error('Erro. Por favor, tente novamente, Mig.');
    logException(`resetPasswordError ${email}: ${error.message}`, false);
    console.error(error);
  }
}

export function* confirmResetPassword({ newPassword, token, tenant }) {
  try {
    sessionStorage.setItem('tenantLogout', tenant);

    const info = atob(token);
    const index = info.lastIndexOf(':');
    const email = info.substring(0, index);
    const ticket = info.substring(index + 1);

    const payload = {
      email,
      ticket,
      password: newPassword,
    };
    const response = yield axios.put(
      `${BASE_API}/${tenant}/account/confirm-reset-password`,
      payload
    );

    if (response.status === 200) {
      toast.success('Sua senha foi redefenida com sucesso');
      yield put(push('/login'));
      logEvent(
        'Confirm Reset password Success',
        'dashboard.login.confirmresetpassword',
        'User confirme reset password sucessfully'
      );
    }
  } catch (error) {
    logException(`confirmResetPassword: ${error.message}`, true);
    toast.error('Ops, não foi possível resetar a sua senha. Tente novamente');
    yield put(push('/recuperar-senha'));
    console.error(error);
  }
}

export async function newConfirmResetPassword(token, password, tenant) {
  try {
    const info = atob(token);
    const index = info.lastIndexOf(':');
    const email = info.substring(0, index);
    const ticket = info.substring(index + 1);

    await axios.put(`${BASE_API}/${tenant}/account/confirm-reset-password`, {
      ticket,
      email,
      password,
    });
    return true;
  } catch (error) {
    console.log(`Error on confirmResetPassword: `, error);
    toast.error('Ops, não foi possível resetar a sua senha. Tente novamente');
    return false;
  }
}

export function* updatePassword({ password, newPassword }) {
  try {
    const payload = {
      password,
      newPassword,
    };
    const response = yield axios.put(`${BASE_API}/account/update-password`, payload);

    if (response.status === 200) {
      toast.success('Senha atualizada com sucesso.');
      yield put(push('/perfil'));
      logEvent(
        'Update password Success',
        'dashboard.login.updatepassword',
        'User update password sucessfully'
      );
      yield put(fromActions.updatePasswordSuccess(response.data));
    }
  } catch (error) {
    if (error.response.status === 401) {
      logException(
        `updatePassword ${password} current password does not match. : ${error.message}`,
        true
      );
      toast.error('Senha atual não confere.');
    } else {
      yield put(fromActions.updatePasswordError(error));
      logException(
        `updatePassword ${password} -> ${newPassword} can be a error 500. : ${error.message}`,
        true
      );
      toast.error('Ops, não foi possível atualizar a senha.');
    }
  }
}

export function* confirmEmail({ tenant, token }) {
  try {
    sessionStorage.setItem('tenantLogout', tenant);

    const info = atob(token);
    const index = info.lastIndexOf(':');
    const email = info.substring(0, index);
    const ticket = info.substring(index + 1);

    const payload = {
      email,
      ticket,
    };
    const response = yield axios.post(`${BASE_API}/${tenant}/auth/email/confirmation`, payload);

    if (response.status === 200) {
      logEvent(
        'Confirm email Success',
        'dashboard.confirm.email',
        'User confirme email sucessfully'
      );
      yield put(fromActions.confirmEmailSuccess(response.data));
    }
  } catch (error) {
    logException(`confirmemail: ${error.message}`, true);
    toast.error('Ops, não foi possível confirmar seu e-mail. Tente novamente');
    console.error(error);
    yield put(fromActions.confirmEmailError(error));
  }
}

export function* watchAuth() {
  yield takeLatest(fromTypes.SIGN_IN, signIn);
  yield takeLatest(fromTypes.SIGN_OUT, signOut);
  yield takeLatest(fromTypes.CHECK_TOKEN_RECOVER_PASSWORD, checkToken);
  yield takeLatest(fromTypes.RESET_PASSWORD, resetPassword);
  yield takeLatest(fromTypes.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(fromTypes.CONFIRM_RESET_PASSWORD, confirmResetPassword);
  yield takeLatest(fromTypes.CONFIRM_EMAIL, confirmEmail);
}
