import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import stylesUaubox from './login.module.scss';
import stylesScarlet from './loginScarlet.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import planList from '../../components/utils/planList';
import queryString from 'query-string';
import { isNil } from 'lodash';

const Login = () => {
  const dispatch = useDispatch();
  const tenantSelected = useSelector((state) => state?.form?.signInForm?.values?.tenant);
  const [tenantAlreadySelected, setTenantAlreadySelected] = useState(false);

  const legacyUrl = `${process.env.REACT_APP_REGISTRATION_WEBSITE_DEFAULT}`;

  const styles = tenantSelected === 'scarlet' ? stylesScarlet : stylesUaubox;

  function sendToCheckout() {
    // eslint-disable-next-line
    window.location.assign(`${legacyUrl}${location?.search}`);
    return;
  }

  useEffect(() => {
    // eslint-disable-next-line
    const params = new URLSearchParams(location.search);

    const utm_source = params.get('utm_source');
    const utm_campaign = params.get('utm_campaign');
    const utm_medium = params.get('utm_medium');

    if (!utm_source) return;

    const data = {
      utm_source,
      utm_campaign,
      utm_medium,
    };

    localStorage.setItem('subscription-media-tracking', JSON.stringify(data));
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (!tenantAlreadySelected) {
      setTenantAlreadySelected(true);
      const tenantLogout = sessionStorage.getItem('tenantLogout');
      if (isNil(tenantLogout)) {
        const registrationTenant = queryString.parse(window.location.search);
        if (registrationTenant.type) {
          dispatch(change('signInForm', 'tenant', registrationTenant.type));
        } else if (registrationTenant.plan) {
          const planIndex = planList.findIndex((plan) =>
            plan.ids.includes(registrationTenant.plan)
          );
          if (planIndex !== -1) {
            dispatch(change('signInForm', 'tenant', planList[planIndex].tenant));
          }
        } else {
          dispatch(change('signInForm', 'tenant', 'uaubox'));
        }
      } else {
        dispatch(change('signInForm', 'tenant', tenantLogout));
      }
    }
  }, [dispatch, tenantSelected, tenantAlreadySelected]);

  return (
    <div className={styles.login}>
      <div className={styles.grid}>
        <div className={styles.mainBox}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/lp-scarletbox/logo_scarlet_preto.png'
            }
            alt={tenantSelected === 'uaubox' ? 'UAUBox' : 'Scarlet'}
          />
          <h1>Acesse Sua Conta</h1>
          <h2>Use uma das opções para poder acessar.</h2>
          <div className={styles.actionBox}>
            {/*eslint-disable-next-line */}
            <Link className={styles.link} to={`/login/password${location?.search}`}>
              <Button label="Entrar com e-mail e senha" variant="outline" />
            </Link>
            {/*eslint-disable-next-line */}
            <Link className={styles.link} to={`/login/code${location?.search}`}>
              <Button label="Entrar com chave de acesso" variant="outline" />
            </Link>
            <div className={styles.separatorBox}>
              <div className={styles.separator} />
              <h3>Ainda não é assinante?</h3>
              <div className={styles.separator} />
            </div>
            <Button label="Assine agora!" variant="outline" onClick={() => sendToCheckout()} />
          </div>
        </div>
        <div className={styles.imgLogin}>
          <img
            src={
              tenantSelected === 'uaubox'
                ? 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png'
                : 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg'
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
