import z from 'zod';

const email = (value) => {
  const validation = z.string().email().safeParse(value);
  return validation.success;
};

const cpf = (value) => {
  const cleanValue = value.replace(/[^\d]+/g, '');
  if (cleanValue.length !== 11 || !!cleanValue.match(/(\d)\1{10}/)) return false;
  const digits = cleanValue.split('').map((el) => Number(el));
  const rest = (count) =>
    ((digits.slice(0, count - 12).reduce((sum, el, index) => sum + el * (count - index), 0) * 10) %
      11) %
    10;
  return rest(10) === Number(cleanValue[9]) && rest(11) === Number(cleanValue[10]);
};

const validate = {
  email,
  cpf,
};

export default validate;
